.sidebar {
  min-height: calc(100vh - #{$navbar-height});
  background: $sidebar-light-bg;
  width: $sidebar-width-lg;
  z-index: 11;
  transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;

  .nav {
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;
    margin-bottom: 60px;

    .nav-item {
      @include transition-duration(0.25s);
      transition-property: background;
      -webkit-transition-property: background;
      .ReactCollapse--collapse {
        transition: height 500ms;
      }
      .collapse {
        z-index: 999;
      }

      .nav-link {
        @include display-flex;
        @include align-items(center);
        white-space: nowrap;
        padding: $sidebar-menu-padding-top $sidebar-menu-padding-right $sidebar-menu-padding-bottom $sidebar-menu-padding-left;
        color: $sidebar-light-menu-color;
        @include transition-duration(0.45s);
        transition-property: color;
        -webkit-transition-property: color;
        cursor: pointer;

        i {
          color: inherit;

          &.menu-icon {
            font-size: $sidebar-icon-font-size;
            line-height: 1;
            margin-right: 1rem;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
            color: $sidebar-light-menu-icon-color;
            &:before {
              vertical-align: middle;
            }
          }

          &.menu-arrow {
            display: inline-block;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            margin-left: auto;
            margin-right: 0;
            color: $sidebar-light-menu-arrow-color;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
            &:before{
              content: "\e649";
              font-family: "themify";
              font-style: normal;
              display: block;
              font-size: 0.687rem;
              line-height: 10px;
              @include transition(all .2s ease-in);
            }
          }
        }

        .menu-title {
          color: inherit;
          display: inline-block;
          font-size: $sidebar-menu-font-size;
          font-weight: $font-weight-medium;
          line-height: 1;
          vertical-align: middle;
        }

        &.menu-expanded {
          background: $sidebar-light-menu-active-bg;
          border-radius: 0.437rem 0.437rem 0 0;
          .menu-arrow{
              transform: rotate(90deg);
          }

        }
      }

      &.active {
        > .nav-link {
          background: $sidebar-light-menu-active-bg;
          position: relative;
          font-size: 13px;
          font-weight: bold;
          &:not(.menu-expanded) {
            border-radius: 0px 20px 20px 0px; 
          }
          i,
          .menu-title,
          .menu-arrow {
            color: $sidebar-light-menu-active-color;
            font-weight: bold;
          }
          .menu-title {
            font-size: 13px;
          }
        }
      }
    }
   
    &:not(.sub-menu) {
      margin-top: 1.45rem;
      margin-left: 0;
      margin-right: 0.375rem;
      > .nav-item {
        margin-top: .2rem;
        &:hover {
          > .nav-link:not(.menu-expanded) {
            background: $sidebar-light-menu-hover-bg;
            color: $sidebar-light-menu-hover-color;
            border-radius: 0px 20px 20px 0px;        
          }
        }
      }
    }
   
    &.sub-menu {
      margin-bottom: 0;
      margin-top:0;
      list-style: none;
      padding: $sidebar-submenu-padding;
      background: $sidebar-light-menu-hover-bg;

      .nav-item {
        &::before {
          content: '';
          width: 5px;
          height: 5px;
          position: absolute;
          margin-top: 16px;
          border-radius: 50%;
          background: #b2b2b2;
        }
        .nav-link {
          color: $sidebar-light-submenu-color;
          padding: $sidebar-submenu-item-padding;
          position: relative;
          font-size: $sidebar-submenu-font-size;
          font-weight: $font-weight-medium;
          line-height: 1;
          &:hover {
            color: $sidebar-light-submenu-hover-color;
          }
          &.active {
            color: $sidebar-light-submenu-active-color;
          }
        }
      }
    }
  }
}


/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - #{$navbar-height});
    top: $navbar-height;
    bottom: 0;
    overflow: auto;
    right: -$sidebar-width-lg;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    &.active {
      right: 0;
    }
  }
}